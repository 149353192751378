import { Space } from 'antd';
import {
  GroupItem,
  HrefType,
  HrefTypeDisPlay,
  NavItem,
  TabKey,
  UpdateNavBody,
  addNav,
  getComprehensiveMenuGroup,
  updateNav,
} from '../../../../api/compositeNavConfig';
import { useEffect, useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Select } from '@maxtropy/components';

const { TextArea } = Input;

interface Props {
  modalOpen: boolean;
  setModalOpen: (v: boolean) => void;
  isEdit: boolean;
  data?: NavItem;
  updateFn: () => void;
}

const formLayout = {
  labelCol: { span: 4, offset: 2 },
  wrapperCol: { span: 15 },
};

const hrefTypeOptions = [
  {
    label: HrefTypeDisPlay[HrefType.NEWWINDOW],
    value: HrefType.NEWWINDOW,
  },
  {
    label: HrefTypeDisPlay[HrefType.CURWINDOW],
    value: HrefType.CURWINDOW,
  },
];

const CusNavEditModal: React.FC<Props> = ({ modalOpen, setModalOpen, isEdit, data, updateFn }) => {
  const [form] = Form.useForm();
  const [cusGroupList, setCusGroupList] = useState<GroupItem[]>([]);

  // 获取分组列表
  useEffect(() => {
    getComprehensiveMenuGroup().then(res => {
      if (res) {
        setCusGroupList((res.list ?? []).filter(i => i.type === TabKey.CUSTOM));
      }
    });
    if (data && isEdit) {
      form.setFieldsValue({
        name: data?.name,
        comprehensiveMenuGroupId: data?.comprehensiveMenuGroupId,
        path: data?.path,
        hrefType: data?.hrefType,
        sortWeight: data?.sortWeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cusOptions = useMemo(() => {
    if (cusGroupList && cusGroupList.length > 0) {
      return cusGroupList.map(i => {
        return { label: i.name, value: i.id };
      });
    }
  }, [cusGroupList]);

  const onOk = () => {
    form.submit();
  };
  const onCancel = () => {
    setModalOpen(false);
  };

  const onFinish = (v: UpdateNavBody) => {
    const body = {
      id: data ? data.id : undefined,
      name: v.name,
      comprehensiveMenuGroupId: v.comprehensiveMenuGroupId,
      path: v.path?.trim().replace(/[\r\n]/g, ''),
      hrefType: v.hrefType,
      sortWeight: v.sortWeight,
      type: TabKey.CUSTOM,
    };
    // 编辑导航
    if (data && isEdit) {
      updateNav(data.id, body).then(res => {
        if (res.influencedLineAmount) {
          updateFn();
        }
      });
      setModalOpen(false);

      return;
    }
    // 新增导航
    addNav(body).then(res => {
      if (res.influencedLineAmount) {
        updateFn();
      }
    });
    setModalOpen(false);
  };
  return (
    <Modal
      open={modalOpen}
      size="large"
      title={`自定义导航${isEdit ? '编辑' : '新增'}`}
      onCancel={onCancel}
      contentClassName="modal-form-content"
      onOk={onOk}
    >
      <Form
        form={form}
        {...formLayout}
        onFinish={onFinish}
        initialValues={{
          hrefType: HrefType.NEWWINDOW,
        }}
      >
        <Form.Item
          label="导航名称"
          name="name"
          rules={[
            { required: true, message: '请输入名称', whitespace: true },
            {
              pattern: /^[\u4e00-\u9fa5]+$/,
              message: '请输入中文',
            },
            { max: 12, message: '最多输入12个中文字符' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          extra={
            cusGroupList.length === 0 && (
              <Space
                size={3}
                style={{
                  fontSize: 12,
                  color: 'var(--mx-text-secondary-color)',
                }}
              >
                <InfoCircleOutlined
                  style={{
                    color: '#faad14 ',
                  }}
                />
                请先建立分组
              </Space>
            )
          }
          label="所属分组"
          name="comprehensiveMenuGroupId"
          rules={[{ required: true, message: '请选择' }]}
        >
          <Select options={cusOptions} placeholder="请选择" />
        </Form.Item>

        <Form.Item label="导航链接" name="path" rules={[{ required: true, message: '请输入', whitespace: true }]}>
          <TextArea rows={4} placeholder="请输入" />
        </Form.Item>
        <Form.Item label="跳转方式" name="hrefType" rules={[{ required: true, message: '请选择' }]}>
          <Select options={hrefTypeOptions} />
        </Form.Item>
        <Form.Item
          label="排序权重"
          name="sortWeight"
          rules={[
            { required: true, message: '' },
            {
              validator: async (_, value: string) => {
                if (!value) {
                  throw new Error('请输入权重');
                }
                if (Number(value) <= 0 || (value.length > 1 && value.slice(0, 1) === '0')) {
                  throw new Error('请输入正整数');
                }
                let regEn = /[^0-9]+/im;
                if (regEn.test(value)) {
                  throw new Error('请输入整数');
                }
              },
            },
          ]}
          extra={
            <Space
              size={3}
              style={{
                fontSize: 12,
                color: 'var(--mx-text-secondary-color)',
              }}
            >
              <InfoCircleOutlined
                style={{
                  color: 'var(--mx-warning-color)',
                }}
              />
              分组内权重越大越靠前
            </Space>
          }
        >
          <Input placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CusNavEditModal;
