import './public-path';
import React, { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { isDev, microAppRewrite, ThemeProvider, Themes } from '@maxtropy/components';
import './index.scss';
import App from './App';
import 'dayjs/locale/zh-cn';

microAppRewrite();

const container = document.getElementById('root');
const root = createRoot(container!);
const StrictModeComponent = isDev() ? Fragment : StrictMode;
const theme = window.THEME === Themes.LIGHT ? Themes.LIGHT : Themes.DARK;
// const theme = Themes.DARK;

root.render(
  <StrictModeComponent>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StrictModeComponent>
);
