import React, { useState } from 'react';
import { Space } from 'antd';
import styles from '../index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Input, message, Modal, Table, CustomerAndUser } from '@maxtropy/components';
import { deleteCustomer } from '../../../api/customer';
import { CurrentDataProps } from './Manage';

interface DeleteModalProps {
  visible: boolean;
  onCancel: () => void;
  currentData?: CurrentDataProps;
  user?: CustomerAndUser;
}

const columns = [
  {
    title: '组织层级',
  },
  {
    title: '组织code',
  },
  {
    title: '组织名',
  },
];

const DeleteModal: React.FC<DeleteModalProps> = props => {
  const { onCancel, currentData, user } = props;
  const [opened, setOpened] = useState<boolean>(props.visible);
  const [hasProject] = useState<boolean>(false);
  const [operationPassword, setOperationPassword] = useState<string>('');

  const onSubmit = async () => {
    if (operationPassword) {
      try {
        await deleteCustomer(currentData!.mcid, {
          muid: user!.customerUser.muid,
          operationPassword: operationPassword,
        }).then(data => {
          onCancelAction();
        });
      } catch (e: any) {
        message.error(e.errorMessage);
      }
    } else {
      message.error('请输入操作密码');
    }
  };

  const onCancelAction = () => {
    setOperationPassword('');
    setOpened(false);
    onCancel();
  };

  return (
    <Modal size="small" title="删除组织" open={opened} onOk={onSubmit} onCancel={onCancelAction}>
      {hasProject ? (
        <div>
          <p style={{ color: 'var(--mx-text-secondary-color)' }}>抱歉，不能删除有项目的组织！</p>
          <Table columns={columns} />
          <p className={styles.gray}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
            如确定要删除该组织，请前往处理对应的项目
          </p>
        </div>
      ) : (
        <div>
          <p style={{ color: 'var(--mx-text-secondary-color)' }}>
            组织一旦删除，组织和组织相关业务数据将<span className={styles.red}>不能恢复</span>
            ，请输入操作密码以确定一定要执行相关操作
          </p>
          <p className={styles.gray}>
            <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
            删除后组织下的全部员工都作废（但用户账号不作废）
          </p>
          <Space style={{ marginTop: 10 }}>
            <span>操作密码：</span>
            <Input.Password
              style={{ width: 340 }}
              placeholder="请输入操作密码"
              value={operationPassword}
              autoComplete="new-password"
              onChange={e => {
                setOperationPassword(e.target.value);
              }}
            />
          </Space>
          <p className={styles.hint}>
            没有设置操作密码？点击前往
            <Link to={'/user/settings/operatePassword'} target="_blank">
              设置
            </Link>
          </p>
        </div>
      )}
    </Modal>
  );
};

export default DeleteModal;
